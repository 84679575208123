<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="organisationCourseAdd()">{{cvbtnAddNew}}</b-button>
          </template>
          <template v-slot:body>
            <b-row class="mb-3">
              <b-col md="4">
                <b-form-input v-model="filter" type="search" :placeholder="cvSearchText"></b-form-input>
              </b-col>
              <!-- <b-col md="8">
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Excel</b-button>
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Csv</b-button>
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Coustomize</b-button>
              </b-col> -->
            </b-row>
            <b-row>
              <b-col md="12" v-if="organisationCourseObjList && organisationCourseObjList.length > 0">
                <b-table hover responsive :items="organisationCourseObjList" :fields="columns" :no-border-collapse="true"
                  :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                  :current-page="currentPage" :per-page="perPage">
                  <template v-slot:cell(created_on)="data">
                    {{getFormattedDateTime(data.item.created_on)}}
                  </template>
                  <template v-slot:cell(org_id)="data">
                    <p v-html="data.item.org_id" style="word-wrap: break-word">
                    </p>
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm"
                      @click="organisationCourseEdit('MODAL', data.item)" v-if="!data.item.editable"><i
                        class="ri-ball-pen-fill m-0"></i></b-button>
                    <b-button variant=" iq-bg-danger" @click="showUniversityCourseDeleteDialog(data.item)" size="sm"><i
                        class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
              </b-col>
              <b-col md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1"></b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"
                  class="my-0"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal v-model="showModelUniversityCourseAdd" scrollable :title="cvAddModalHeader" size="xl">
      <UniversityCourseAdd :propOpenedInModal="true"
        @emitCloseUniversityCourseAddModal="closeUniversityCourseAddModal" />
      <template >
        <b-button size="sm" class="pull-left" @click="organisationCourseAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeUniversityCourseAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelUniversityCourseEdit" scrollable :title="cvEditModalHeader" size="xl">
      <UniversityCourseEdit :propOpenedInModal="true" :propUniversityCourseObj="organisationCourseEditObj"
        @emitCloseUniversityCourseEditModal="closeUniversityCourseEditModal" />
      <template >
        <b-button size="sm" class="pull-left" @click="organisationCourseEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeUniversityCourseEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelUniversityCourseDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template>
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelUniversityCourseDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="organisationCourseDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { UniversityCourses } from "../../../FackApi/api/universityCourse.js"
import UniversityCourseAdd from "./UniversityCourseAdd.vue"
import UniversityCourseEdit from "./UniversityCourseEdit.vue"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse.js"

export default {
  name: "UniversityCourseList",
  components: {
    UniversityCourseAdd,
    UniversityCourseEdit
  },
  data () {
    return {
      cvCardTitle: "University Courses",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit UniversityCourse",
      cvAddModalHeader: "Add UniversityCourse",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "UniversityCourse List Response",
      showModelUniversityCourseAdd: false,
      showModelUniversityCourseEdit: false,
      showModelUniversityCourseDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left", sortable: true },

        { label: "org name", key: "org_name", class: "text-left", sortable: true },
        { label: "org id", key: "org_id", class: "text-left", sortable: true },
        { label: "college id", key: "college_id", class: "text-left", sortable: true },
        { label: "cour degree name", key: "cour_degree_name", class: "text-left", sortable: true },
        { label: "cour name", key: "cour_name", class: "text-left", sortable: true },
        { label: "cour desc", key: "cour_desc", class: "text-left", sortable: true },
        { label: "cour curr", key: "cour_curr", class: "text-left", sortable: true },
        { label: "cour tuition min", key: "cour_tuition_min", class: "text-left", sortable: true },
        { label: "cour tuition max", key: "cour_tuition_max", class: "text-left", sortable: true },
        { label: "cour scholarship min", key: "cour_scholarship_min", class: "text-left", sortable: true },
        { label: "cour scholarship max", key: "cour_scholarship_max", class: "text-left", sortable: true },
        { label: "cour adm cycle", key: "cour_adm_cycle", class: "text-left", sortable: true },
        { label: "Created On ", key: "created_on", class: "text-left", sortable: true },
        { label: "Action ", key: "action", class: "text-left", sortable: false }
      ],
      organisationCourseObjList: null,
      organisationCourseEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      delObj: null
    }
  },
  mounted () {
    this.organisationCourseList()
  },
  methods: {
    /**
     * getFormattedDateTime
     */
    getFormattedDateTime (datetime) {
      try {
        return moment(datetime).format("DD-MM-YYYY hh:mm:ss")
      }
      catch (err) {
        console.log("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    /**
     * organisationCourseList
     */
    async organisationCourseList () {
      try {
        let organisationCourseListResp = await UniversityCourses.organisationCourseList(this)
        if (organisationCourseListResp.resp_status) {
          this.organisationCourseObjList = organisationCourseListResp.resp_data.data
          this.totalRows = organisationCourseListResp.resp_data.count
        }
        else {
          this.toastMsg = organisationCourseListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at organisationCourseList() and Exception:", err.message)
      }
    },
    /**
     * organisationCourseAdd
     */
    organisationCourseAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/organisationCourse_add")
        }
        else {
          this.showModelUniversityCourseAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at organisationCourseAdd() and Exception:", err.message)
      }
    },
    /**
     * organisationCourseEdit
     */
    organisationCourseEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/organisationCourse_edit/" + this.organisationCourseEditObj.cour_id)
        }
        else {
          this.organisationCourseEditObj = item
          this.showModelUniversityCourseEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at organisationCourseEdit() and Exception:", err.message)
      }
    },
    /**
     * showUniversityCourseDeleteDialog
     */
    showUniversityCourseDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelUniversityCourseDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showUniversityCourseDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * organisationCourseDelete
     */
    async organisationCourseDelete () {
      try {
        let organisationCourseDelResp = await UniversityCourses.organisationCourseDelete(this, this.delObj.cour_id)
        await ApiResponse.responseMessageDisplay(this, organisationCourseDelResp)

        if (organisationCourseDelResp && !organisationCourseDelResp) {
          this.showModelUniversityCourseDelete = false
          return false
        }

        let index = this.organisationCourseObjList.indexOf(this.delObj)
        this.organisationCourseObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelUniversityCourseDelete = false
      }
      catch (err) {
        console.error("Exception occurred at organisationCourseDelete() and Exception:", err.message)
      }
    },
    /**
     * closeUniversityCourseAddModal
     */
    closeUniversityCourseAddModal (organisationCourseAddData) {
      try {
        if (organisationCourseAddData) {
          if (this.organisationCourseObjList && this.organisationCourseObjList.length >= 1) {
            let courObjLength = this.organisationCourseObjList.length
            let lastId = this.organisationCourseObjList[courObjLength - 1]["id"]
            organisationCourseAddData.id = lastId + 1
          }
          else {
            this.organisationCourseObjList = []
            organisationCourseAddData.id = 11111
          }

          organisationCourseAddData.created_on = moment()
          this.organisationCourseObjList.unshift(organisationCourseAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelUniversityCourseAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closeorganisationCourseAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeUniversityCourseEditModal
     */
    closeUniversityCourseEditModal () {
      try {
        this.showModelUniversityCourseEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeUniversityCourseEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
